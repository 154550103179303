import Observable from 'zen-observable';
import cloneDeep from 'lodash/cloneDeep';
import widgetSessionStorage from '@/services/session/widgets';
import reduceAnswers from './utils/reduceAnswers';
import spreadsheetUtils from '@/utils/spreadsheets';
import { getDefaultReportRequests } from '@/utils/reporting/widgetQueries';
import workbookStorage from '@/services/storage/workbooks';
import { toLastCellReferenceSelection } from '@/utils/widget/parseSelections';
import { nowUnix } from '@/utils/date';
import logger from '@/utils/logger';
const PREFIX = 'common: components: reporting: Questonnaire: useCreateResults:';
export default function useCreateResults(reportingWidgetId, spreadsheetUrl, workbook, workbookMeta) {
  // Generate a new results for reporting widget
  // based on users latest answers & reports,
  // then save results to session storage
  function publishAll(formElements, questions) {
    let onComplete = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => undefined;
    let onError = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => undefined;
    widgetSessionStorage.clearResults(reportingWidgetId); // Remove blank questions that don't have
    // any data selections and can't affect results

    const connectedQuestions = (questions || []).filter(question => Boolean(question.selections));
    const userAnswers = reduceAnswers({
      questions: connectedQuestions,
      formElements,
      reducer: (acc, question, userAnswer) => {
        const selection = toLastCellReferenceSelection(question.selections);
        acc[selection] = userAnswer;
        return acc;
      }
    }); // Create spreadsheet's results stream

    const observable = createResultsStream({
      userAnswers,
      spreadsheetUrl,
      workbook,
      workbookMeta
    });
    const widgetsResults = []; // Subscibe to each report's generation
    // and transtion once last report published

    const subscription = observable.subscribe({
      next(result) {
        result && widgetsResults.push(result);
      },

      async complete() {
        // Save all the widgets' results to session storage
        try {
          widgetSessionStorage.saveResults(reportingWidgetId, widgetsResults);
        } catch (err) {
          logger.error(Error(`${PREFIX} failed to save results for widget: "${reportingWidgetId}": ${err}`));
        } // Close report stream


        subscription.unsubscribe();
        onComplete();
      },

      error(err) {
        onError(err);
      }

    });
    return subscription;
  }

  return {
    publishAll
  };
} // Create an observable stream for all
// the report to publish

function createResultsStream(config) {
  const {
    userAnswers,
    spreadsheetUrl,
    workbook,
    workbookMeta
  } = config;
  const reports = getDefaultReportRequests();

  const requestResults = observer => {
    const report = reports.shift();

    if (!report || observer.closed) {
      observer.complete();
      return;
    } // Interpret a report for current config


    createResult({
      report,
      userAnswers,
      spreadsheetUrl,
      workbook,
      workbookMeta
    }).then(result => {
      result && observer.next(result);
      requestResults(observer);
    }).catch(err => {
      const wrappedError = Error(`${PREFIX} createResultsStream: requestResults: create results failed for report: ${err}`);
      wrappedError.stack = err.stack;

      if (err.items) {
        // @ts-ignore
        wrappedError.items = err.items;
      }

      observer.error(wrappedError);
    });
  };

  return new Observable(requestResults);
}

// Create a new report for a
// form state and report request
export async function createResult(config) {
  const {
    report,
    userAnswers,
    spreadsheetUrl,
    workbook: configWorkbook,
    workbookMeta
  } = config; // Load workbook from provided URL

  let srcWorkbook;

  if (configWorkbook) {
    srcWorkbook = configWorkbook;
  } else {
    try {
      const workbookDownload = await workbookStorage.downloadWorkbook(spreadsheetUrl, true);
      srcWorkbook = workbookDownload;
    } catch (err) {
      // Wrap error
      const wrappedError = Error(`${PREFIX} createResult: failed to load spreadsheet at "${spreadsheetUrl}" report: ${err}`);
      wrappedError.stack = err.stack;
      throw wrappedError;
    }
  } // Factory for inserting report answers into
  // a workbook, returning new instances of workbook


  const updateAnswer = spreadsheetUtils.createCellUpdater(srcWorkbook); // Populate spreadsheat with report or
  // user's answers before generating results

  let workbook;

  try {
    workbook = await new Promise(resolve => {
      const reportAnswers = report.answers || {};
      const answerCellRefs = [...Object.keys(userAnswers), ...Object.keys(reportAnswers)].filter((cellRef, i, arr) => arr.indexOf(cellRef) === i); // Unique only

      const populatedSheet = answerCellRefs.reduce((_, selectionSrc) => {
        const selection = toLastCellReferenceSelection(selectionSrc);
        const value = typeof reportAnswers[selection] !== 'undefined' ? reportAnswers[selection] // Prioritize report's answer
        : userAnswers[selection]; // Fallback to user's answers
        // Update spreadsheet with answer

        return updateAnswer(selection, value);
      }, cloneDeep(srcWorkbook)); // Resolve answer populated spreadsheet
      // or fallback to default spreadsheet

      resolve(populatedSheet);
    });
  } catch (err) {
    const wrappedError = Error(`${PREFIX} createResult: failed to populate user answers: ${err}`);
    throw wrappedError;
  } // Compile workbook


  let resultWorkbook;

  try {
    resultWorkbook = await spreadsheetUtils.strictCompile(workbook, workbookMeta ? workbookMeta.sheetNames : undefined);
  } catch (err) {
    const wrappedError = Error(`${PREFIX} createResult: failed to generate results for "${report.id}": ${err}`); // @ts-ignore

    wrappedError.items = err.items;
    wrappedError.stack = err.stack;
    throw wrappedError;
  }

  const now = nowUnix();
  return {
    id: report.id,
    name: report.name || '',
    createdAt: now,
    updatedAt: now,
    data: [...resultWorkbook]
  };
}