import design, { _remCalc } from '@/config/design';
import styles from './styles.module.scss'; // Layout Specific Styles

import summaryStyles from './variants/Summary/styles.module.scss';
export default {
  styles: {
    remBase: `${design.remBase}`,
    widgetHeight: 'auto',
    maxWidth: _remCalc(design.maxWidth),
    backgroundColor: design.colors.white,
    globalXsmPadding: _remCalc(design.globalPaddings.xsmPadding),
    globalSmPadding: _remCalc(design.globalPaddings.smPadding),
    globalMdPadding: _remCalc(design.globalPaddings.mdPadding),
    globalPadding: _remCalc(design.globalPaddings.padding),
    globalLgPadding: _remCalc(design.globalPaddings.lgPadding),
    globalXlgPadding: _remCalc(design.globalPaddings.xlgPadding),
    globalXxlgPadding: _remCalc(design.globalPaddings.xxlgPadding),
    globalXxxlgPadding: _remCalc(design.globalPaddings.xxxlgPadding),
    globalXxxxlgPadding: _remCalc(design.globalPaddings.xxxxlgPadding),
    globalXxxxxlgPadding: _remCalc(design.globalPaddings.xxxxxlgPadding)
  }
};
const LAYOUT_CLASSES = {
  // Clothsline Layout
  standard: {
    defaults: [styles.container]
  },
  sidebarClothesline: {
    defaults: [styles.container]
  },
  // Summary Sidebar Layout
  summary: {
    defaults: [styles.container, summaryStyles.container]
  }
}; // Optional sectional groups for layouts
// NOTE: Should be sorted alphabetically

export const SECTION_GROUPS = {
  summary: ['sidebar']
}; // Generate layout classes for the
// configured layout and container size

export function getLayoutClassNames(layout) {
  const layoutGroup = LAYOUT_CLASSES[layout] || LAYOUT_CLASSES.standard;
  const classNames = [...layoutGroup.defaults];
  return classNames.join(' ');
}