import design from '@/config/design';
import styles from './styles.module.scss';
export default {
  styles: {
    mainCellsBorderWidth: '0'
  },
  sectionDefaultStyles: {
    [`.${styles.main}`]: {
      borderTopWidth: '0',
      borderRightWidth: '0',
      borderBottomWidth: '0',
      borderLeftWidth: '0',
      borderTopStyle: 'solid',
      borderRightStyle: 'solid',
      borderBottomStyle: 'solid',
      borderLeftStyle: 'solid',
      borderTopColor: design.colors.gray20,
      borderRightColor: design.colors.gray20,
      borderBottomColor: design.colors.gray20,
      borderLeftColor: design.colors.gray20,
      paddingTop: design.globals.padding,
      paddingRight: design.globals.lgPadding,
      paddingBottom: design.globals.padding,
      paddingLeft: design.globals.lgPadding
    }
  }
};