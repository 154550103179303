// import design from '@/config/design';
export default {
  styles: {
    borderTopWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '0',
    borderLeftWidth: '0'
  },
  breakpoints: {
    threeColumnLayoutMinWidth: 520,
    fourColumnLayoutMinWidth: 640,
    expandedLayoutMinWidth: 700
  }
};
export const selectionConfig = {};