import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.6_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import flatten from 'lodash/flatten';
// Question Ruleset definition #<question-id>,<operator>,<value>[OR | AND][<operator>],[<value>]...
// Convert an encoded string into a ruleset definition
export function decodeQuestionHideWhen(encodedHideWhen) {
  const questionRules = getQuestionRulesets(encodedHideWhen).filter(ruleSet => ruleSet.split(',').length > 2) // is valid ruleset definition
  .map(ruleSet => {
    const targetQuestionId = extractQuestionId(ruleSet);
    const inclusiveRules = ruleSet.slice(ruleSet.indexOf(',') + 1) // remove question id
    .split('OR') // Get list of all inclusive rules
    // Include only well formed rules
    // (each inclusive rule must be composed of valid exclusive rules)
    .filter(ruleGroup => ruleGroup.split('AND').every(incGroup => incGroup.split(',').filter(Boolean).length > 1)); // Split inclusive rules into
    // separate exclusive rules

    const exclusiveRules = inclusiveRules.map(ruleGroup => ruleGroup.split('AND')).map(exclusiveRules => {
      return flatten(exclusiveRules.map(rule => rule.replace(/^,|,$/g, '').split(',')).map(_ref => {
        let [operator, value] = _ref;
        return [operator, decodeValue(value)];
      }));
    });
    return {
      [targetQuestionId]: exclusiveRules
    };
  }) // Merge all question rules together
  .reduce((acc, questionRules) => {
    return _objectSpread(_objectSpread({}, acc), questionRules);
  }, {});
  return {
    questions: questionRules
  };
} // Convert a HideWhen ruleset into an encoded string

export function encodeQuestionHideWhen(hideWhen) {
  return Object.keys(hideWhen.questions).reduce((acc, emitterQuestionId) => {
    const inclusiveRules = (hideWhen.questions[emitterQuestionId] || []).map(exclusiveRules => {
      let accGroup = ''; // Convert each exclusive rule into a string

      for (let i = 0; i < exclusiveRules.length; i += 2) {
        const operator = exclusiveRules[i];
        const value = exclusiveRules[i + 1];
        if (!operator || value === undefined) continue;
        accGroup += `${operator},${encodeValue(value)},AND,`;
      }

      return accGroup.replace(/,AND,$/, '');
    });
    if (!inclusiveRules.length) return acc;
    acc += `#${emitterQuestionId},${inclusiveRules.join(',OR,')}|`;
    return acc;
  }, '').replace(/\|$/, '').replace(/,OR,$/, '');
}
export default {
  encode: encodeQuestionHideWhen,
  decode: decodeQuestionHideWhen
}; // Parse the value in rule into a
// string, boolean or numeric value

function decodeValue(val) {
  const decoded = decodeURIComponent(val); // If value is wrapped with "'" characters
  // return string value without apostrophies

  if (decoded.indexOf("'") === 0 && decoded.lastIndexOf("'") === decoded.length - 1) {
    return decoded.slice(1, -1);
  } else if (['true', 'false'].includes(decoded.toLowerCase())) {
    return decoded.toLowerCase() === 'true'; // return boolean
  } // Parse value as a number


  const num = parseFloat(decoded);
  return isNaN(num) ? -1 : num;
} // Encode a string so that it
// has quotes surrounding it


function encodeValue(val) {
  if (typeof val === 'string') {
    return `'${val}'`;
  }

  return `${val}`;
} // Get all rulesets that are based on questions


export function getQuestionRulesets(rules) {
  return rules.split('|').filter(ruleSet => ruleSet[0] === '#');
} // Pull out the question ID from a ruleset

export function extractQuestionId(ruleSet) {
  return ruleSet.slice(1, ruleSet.indexOf(','));
} // Return a list of all question ID's that
// emit values affecting a question's hideWhen

export function getQuestionRulesetEmitters(hideWhen) {
  return getQuestionRulesets(hideWhen).map(extractQuestionId);
} // Append an inclusive rule containing
// a single invalid exclusive rule

export function addInclusiveRule(inclusiveRules) {
  const update = [...inclusiveRules];
  update.push(['==', '']);
  return update;
} // Append an empty exclusive rule
// to the last inclusive ruleset

export function addExclusiveRule(inclusiveRules) {
  const lastExclusiveRules = inclusiveRules[inclusiveRules.length - 1] || [];
  lastExclusiveRules.push('==', '');
  return [...inclusiveRules.splice(0, -1), lastExclusiveRules];
} // Update the content of an existing
// exclusive rule at a given index

export function updateInclusiveRule(inclusiveRules, index, exclusiveRules) {
  let update = [...inclusiveRules];
  update.splice(index, 1, exclusiveRules); // replace

  update = update.filter(inclusive => inclusive.length > 0); // remove empty inclusive rules

  return update;
} // Merge a target inclusive rulest into another
// to create a larger group of exclusive rules

export function combineInclusiveRules(inclusiveRules, mergeIndex, targetIndex) {
  const update = [...inclusiveRules];
  const exclusiveRulesA = inclusiveRules[mergeIndex];
  const exclusiveRulesB = inclusiveRules[targetIndex];
  exclusiveRulesA.push(...exclusiveRulesB); // Merge target into

  update.splice(targetIndex, 1); // Removed rules that were merged

  return update;
} // Insert a new inclusive rule at
// a specified index and apply updates
// to an optional starting state

export function insertInclusiveRule(inclusiveRules, index, newInclusiveRule) {
  const update = [...inclusiveRules];
  update.splice(index, 0, newInclusiveRule);
  return update;
}
export const edit = {
  addInclusiveRule,
  addExclusiveRule,
  updateInclusiveRule,
  combineInclusiveRules,
  insertInclusiveRule
};