import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.6_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useMemo, useRef, useEffect } from 'react';
import uniq from 'lodash/uniq';
import { utils as utilsCore } from '@industriousapps/excelkits-core';
import questionHideWhen, { getQuestionRulesets, extractQuestionId } from '@/utils/widget/questionHideWhen';
import globalEvents from '@/utils/globalEvents';
const {
  forceToNumber
} = utilsCore; // const PREFIX =
//   'common: components: reporting: Form: useQuestionsReavealFilter:';
// Filter out questions that are omitted
// by their configured hide logic rules
// NOTE: Assumes all question values have been
//       parsed and already have appropriate types

export default function useQuestionsReavealFilter(_ref) {
  let {
    questions: initialQuestions
  } = _ref;
  // Source states recalculated on ever render
  const defaultValues = initialQuestions.reduce((acc, _ref2) => {
    let {
      id,
      defaultValue
    } = _ref2;
    acc[id] = defaultValue;
    return acc;
  }, {}); // Hash of subscriber questions and their rulesets

  const hideWhenRulesSrc = initialQuestions.reduce((acc, _ref3) => {
    let {
      id,
      hideWhen
    } = _ref3;
    if (!hideWhen) return acc;
    acc[id] = hideWhen;
    return acc;
  }, {}); // Hash of user updates

  const questionUpdates = useRef({});
  const questionsState = useMemo(() => {
    return _objectSpread(_objectSpread({}, defaultValues), questionUpdates.current); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashToString(questionUpdates.current), hashToString(defaultValues)]); // Question states that can be edited by authors
  // used to rerender questions source question changes
  // TODO: enable only when widget is in edit mode

  const questionOtherEditableStates = useMemo(() => initialQuestions.reduce((acc, _ref4) => {
    let {
      id,
      name = '',
      layout,
      valueType,
      heading,
      tooltip = '',
      placeholder = '',
      minimumValue = 0,
      maximumValue = 0,
      minimumValueWarning = '',
      step = 0,
      options = '',
      defaultChecked = false,
      checkedValue = '',
      uncheckedValue = '',
      required = false
    } = _ref4;
    acc[id] = `${name}${layout}${valueType}${heading}${tooltip}${placeholder}${minimumValue}${maximumValue}${minimumValueWarning}${step}${options}${defaultChecked}${checkedValue}${uncheckedValue}${required}`;
    return acc;
  }, {}), [initialQuestions]); // Question event emitters
  // (AKA questions being subscribed too)

  const questionEmitters = useMemo(() => {
    const emitterIds = Object.keys(hideWhenRulesSrc).reduce((acc, subscriberId) => {
      const emittersForQuestion = getQuestionRulesets(hideWhenRulesSrc[subscriberId]).map(extractQuestionId);
      acc.push(...emittersForQuestion);
      return acc;
    }, []);
    return uniq(emitterIds); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashToString(hideWhenRulesSrc)]); // Sync question states of questions
  // that are event emitters, which can
  // cause other questions to hide based
  // on their values

  useEffect(() => {
    const unsubscribers = questionEmitters.map(emitterId => {
      return globalEvents.subscribe(`questionchange.${emitterId}`, evt => {
        questionUpdates.current = _objectSpread(_objectSpread({}, questionUpdates.current), {
          [emitterId]: evt.detail
        });
      });
    }); // Unsubscribe each question change listener

    return () => unsubscribers.forEach(unsub => unsub()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionEmitters.join('')]); // Recalculate filtered questions when any of
  // the rulesets or question states have changed

  const questions = useMemo(() => {
    return initialQuestions.filter(question => hideWhenRulesSrc[question.id] ? !isHidden(question, questionsState) : true);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [hashToString(hideWhenRulesSrc), hashToString(questionsState), hashToString(questionOtherEditableStates)]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return questions;
} // Determine if a question is hidden
// based on the current question/value map

export function isHidden(question, values) {
  const {
    hideWhen
  } = question;
  if (!hideWhen) return false;
  const rules = questionHideWhen.decode(hideWhen);
  const results = Object.entries(rules.questions).map(_ref5 => {
    let [questionId, inclusiveRules] = _ref5;
    const value = values[questionId]; // true if any of the inclusive
    // rules return true

    return inclusiveRules.map(exclusiveRules => {
      // False as soon as any of the
      // exclusive rules returns false
      for (let i = 0; i < exclusiveRules.length; i += 2) {
        const rule = [exclusiveRules[i], exclusiveRules[i + 1]];
        if (!isHideRuleActive(value, rule)) return false;
      }

      return true;
    }).includes(true);
  }); // Hide when any of the questions
  // being subscribed to return true

  return results.includes(true);
} // Test the value against the
// operator and configured value

function isHideRuleActive(value, rule) {
  const [operator, comparison] = rule;

  if (operator === '==') {
    return value === comparison;
  } else if (operator === '!=') {
    return value !== comparison;
  } else if (operator === '>') {
    return forceToNumber(value) > forceToNumber(comparison);
  } else if (operator === '>=') {
    return forceToNumber(value) >= forceToNumber(comparison);
  } else if (operator === '<') {
    return forceToNumber(value) < forceToNumber(comparison);
  } else if (operator === '<=') {
    return forceToNumber(value) <= forceToNumber(comparison);
  }

  return false;
} // Convert a hash into a single string
// value to determine if state needs updating


function hashToString(obj) {
  return Object.entries(obj).map(ent => ent.join('')).join('');
}