import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.6_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import merge from 'lodash/merge';
import { cloneDeep } from 'lodash';
// import type { CustomCommandsPlugin as CustomCommandsPluginT } from '@/services/univerjs/customCommands';
import { spreadsheetErrorCodes, utils as utilsCore, spreadsheet as spreadsheetCore } from '@industriousapps/excelkits-core';
import environment from '@/config/environment';
import { createSpreadsheetError } from '@/utils/spreadsheets/update';
import { createWrappedError } from '@/utils/errors';
import { deleteEmptyObjects } from '@/utils/objects'; // import { uuid } from '@/utils/uuid';

import { getWorkbookSheets } from './utils'; // List all supported Univer Sheet formulas
// import { enUS } from '@univerjs/sheets-formula';

const PREFIX = 'services: univerjs: index:';
const IS_PRODUCTION = environment.isProduction;
const MIN_SHEET_COLUMNS = 20;
const MIN_SHEET_ROWS = 1000;
const {
  trimEdges,
  addPadding,
  addNonExistentRows,
  addNonExistentColumns,
  coordinatesToSelection,
  cellReferencesToAddress
} = spreadsheetCore;
const {
  getMatrixDimensions
} = utilsCore;
const {
  getSheetName
} = spreadsheetCore;
// Load dependencies for Univer Sheet
// by default loads system deps to compile workbooks
// including UI deps will load styles and rendering code
export async function load() {
  let settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let result;
  const {
    includeUiDeps = false
    /*, excludeStyles = false*/

  } = settings;
  let UniverSheetsEnUS = null;
  let LocaleType = null;
  let UniverCustomFormulasEnUs = null; // Load Style Dependencies first
  // TODO: figure out why style deps block loading
  //       in production, styles not recoverable?
  // if (includeUiDeps && !excludeStyles) {
  //   try {
  //     await Promise.all([
  //       // @ts-ignore
  //       import('@univerjs/design/lib/index.css'),
  //       // @ts-ignore
  //       import('@univerjs/ui/lib/index.css'),
  //       // @ts-ignore
  //       import('@univerjs/sheets-ui/lib/index.css'),
  //       // @ts-ignore
  //       import('@univerjs/sheets-formula/lib/index.css'),
  //     ]);
  //   } catch (err) {
  //     throw Error(`${PREFIX} load: style deps failed: ${err}`);
  //   }
  // }
  // TODO: Disable this hack in future releases
  //       of Univer when local-font check does not throw
  //       errors in FF & Safari (current v0.1.14)

  let originalQueryMethod;

  if (true && window.navigator && window.navigator.permissions && window.navigator.permissions.query) {
    originalQueryMethod = window.navigator.permissions.query;

    window.navigator.permissions.query = () => // @ts-ignore
    Promise.resolve({
      state: 'rejected'
    });
  }

  try {
    const [coreDep, engineFormulaDep, engineRenderDep, sheetsDep, SheetsEnUS, facadeDep, customFormulasDep] = await Promise.all([import('@univerjs/core'), import('@univerjs/engine-formula'), import('@univerjs/engine-render'), import('@univerjs/sheets'), // @ts-ignore
    import('@univerjs/sheets/locale/en-US'), import('@univerjs/facade'), import('@/services/univerjs/customFormulas')]);
    const {
      Univer,
      LogLevel
    } = coreDep;
    const {
      UniverFormulaEnginePlugin
    } = engineFormulaDep;
    const {
      UniverRenderEnginePlugin
    } = engineRenderDep;
    const {
      UniverSheetsPlugin
    } = sheetsDep;
    const {
      FUniver
    } = facadeDep;
    LocaleType = coreDep.LocaleType;
    UniverSheetsEnUS = SheetsEnUS; // Used for UI deps

    const {
      functionDescriptions,
      functionUser
    } = customFormulasDep;
    UniverCustomFormulasEnUs = customFormulasDep.functionEnUS;
    result = {
      // @ts-ignore
      Univer: Univer,
      LogLevel: LogLevel,
      LocaleType: LocaleType,
      // @ts-ignore
      UniverFormulaEnginePlugin: UniverFormulaEnginePlugin,
      // @ts-ignore
      UniverRenderEnginePlugin: UniverRenderEnginePlugin,
      // @ts-ignore
      UniverSheetsPlugin: UniverSheetsPlugin,
      // @ts-ignore
      FUniver: FUniver,
      functionDescriptions,
      functionUser
    };
  } catch (err) {
    throw Error(`${PREFIX} load: primary deps failed: ${err}`);
  }

  if (includeUiDeps) {
    try {
      const [designDep, UniverDesignEnUs, docsDep, sheetsFormulaDep, UniverSheetsFormulaEnUS, sheetsUiDep, UniverSheetsUIEnUS, docsUiDep, UniverDocsUIEnUS, sheetsNumFmtDep, UniverNumFmtEnUs, uiDep, UniverUiEnUS // customCommands,
      ] = await Promise.all([import('@univerjs/design'), // @ts-ignore
      import('@univerjs/design/locale/en-US'), import('@univerjs/docs'), import('@univerjs/sheets-formula'), // @ts-ignore
      import('@univerjs/sheets-formula/locale/en-US'), import('@univerjs/sheets-ui'), // @ts-ignore
      import('@univerjs/sheets-ui/locale/en-US'), import('@univerjs/docs-ui'), // @ts-ignore
      import('@univerjs/docs-ui/locale/en-US'), import('@univerjs/sheets-numfmt'), // @ts-ignore
      import('@univerjs/sheets-numfmt/locale/en-US'), import('@univerjs/ui'), // @ts-ignore
      import('@univerjs/ui/locale/en-US') // import('@/services/univerjs/customCommands'),
      ]);
      const {
        defaultTheme
      } = designDep;
      const {
        UniverDocsPlugin
      } = docsDep;
      const {
        UniverSheetsFormulaPlugin
      } = sheetsFormulaDep;
      const {
        UniverSheetsUIPlugin
      } = sheetsUiDep;
      const {
        UniverDocsUIPlugin
      } = docsUiDep;
      const {
        UniverSheetsNumfmtPlugin
      } = sheetsNumFmtDep;
      const {
        UniverUIPlugin
      } = uiDep; // const { CustomCommandsPlugin } = customCommands;

      result.theme = defaultTheme; // @ts-ignore

      result.UniverDocsPlugin = UniverDocsPlugin; // @ts-ignore

      result.UniverSheetsFormulaPlugin = UniverSheetsFormulaPlugin; // @ts-ignore

      result.UniverSheetsUIPlugin = UniverSheetsUIPlugin; // @ts-ignore

      result.UniverDocsUIPlugin = UniverDocsUIPlugin; // @ts-ignore

      result.UniverSheetsNumfmtPlugin = UniverSheetsNumfmtPlugin; // @ts-ignore

      result.UniverUIPlugin = UniverUIPlugin; // @ts-ignore
      // result.CustomCommandsPlugin = CustomCommandsPlugin;

      result.locales = {
        [LocaleType.EN_US]: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, UniverSheetsEnUS ? UniverDesignEnUs.default : null), UniverDocsUIEnUS.default), UniverSheetsUIEnUS.default), UniverNumFmtEnUs.default), UniverUiEnUS.default), UniverSheetsFormulaEnUS.default), UniverDesignEnUs.default), UniverCustomFormulasEnUs)
      };
    } catch (err) {
      throw Error(`${PREFIX} load: UI deps failed: ${err}`);
    }
  }

  if (originalQueryMethod) {
    window.navigator.permissions.query = originalQueryMethod;
  }

  return result;
} // Load Univer deps and instantiated a core
// instance and wrap it in a Univer Facade

async function loadUniverFacade(customLogLevel, workbookData) {
  let deps;

  try {
    deps = await load();
  } catch (err) {
    throw createWrappedError(`${PREFIX} loadUniver: system dependencies failed to load`, err);
  }

  const {
    Univer,
    LogLevel,
    UniverRenderEnginePlugin,
    UniverFormulaEnginePlugin,
    UniverSheetsPlugin,
    FUniver
  } = deps;
  let univer;

  try {
    // @ts-ignore
    univer = new Univer({
      logLevel: customLogLevel || LogLevel.ERROR // customLogLevel || (IS_PRODUCTION ? LogLevel.ERROR : LogLevel.VERBOSE),

    });
    univer.registerPlugin(UniverRenderEnginePlugin);
    univer.registerPlugin(UniverFormulaEnginePlugin);
    univer.registerPlugin(UniverSheetsPlugin);
    univer.createUniverSheet(workbookData || {});
  } catch (err) {
    throw createWrappedError(`${PREFIX} loadUniver: failed to instantiate univer`, err);
  } // @ts-ignore


  return FUniver.newAPI(univer);
}

// Convert a Workbook into a Univer
// WorkbookData instance:
// https://univer.ai/api/core/interfaces/IWorkbookData.html
export async function workbookToUniverWorkbook(params) {
  let univerAPI;
  const {
    workbook,
    workbookMeta,
    customLogLevel
  } = params;
  const {
    sheetNames = [],
    styles = {},
    formats = [],
    cellData = [],
    grids = []
  } = workbookMeta || {};

  try {
    univerAPI = await loadUniverFacade(customLogLevel);
  } catch (err) {
    throw createWrappedError(`${PREFIX} workbookToUniverWorkbook: loading deps failed`, err);
  }

  try {
    const activeWorkbook = univerAPI.createUniverSheet({});

    if (!activeWorkbook) {
      throw Error(`${PREFIX}: failed to get active workbook`);
    } // Get pre-generated sheets


    const sheets = getWorkbookSheets(activeWorkbook); // Apply workbook sheets' data to each
    // sheet within univer workbook
    // NOTE: waits for promises to resolve to ensure
    //       that cell data in workbook is updated

    try {
      await Promise.all(workbook.map((sheet, index) => {
        const {
          width,
          height
        } = getMatrixDimensions(sheet); // Use pre-generated sheets or create new sheet

        const newSheet = sheets[index] || // name, rows, columns
        activeWorkbook.create(getSheetName(index, sheetNames), // Use Univer defaults or sheet dimensions
        // NOTE: these settings are not respected
        Math.max(MIN_SHEET_ROWS, height), Math.max(MIN_SHEET_COLUMNS, width)); // Apply data to sheet

        if (newSheet) {
          const range = newSheet.getRange(0, 0, height, width);
          return (range === null || range === void 0 ? void 0 : range.setValues(sheet)) || Promise.resolve();
        }

        return Promise.resolve();
      }));
    } catch (err) {
      throw createWrappedError(`${PREFIX}: failed to set workbook data`, err);
    } // Create univer workbook data


    const snap = activeWorkbook.getSnapshot();
    const latestSheets = getWorkbookSheets(activeWorkbook); // Ensure that each worksheet is set to
    // spreadsheet size or the minimum dimension
    // NOTE: Fix for demensions ignored on create

    workbook.forEach((sheet, index) => {
      const worksheet = latestSheets[index];
      const sheetId = worksheet.getSheetId();
      const {
        width,
        height
      } = getMatrixDimensions(sheet);
      snap.sheets[sheetId].rowCount = Math.max(MIN_SHEET_ROWS, height);
      snap.sheets[sheetId].columnCount = Math.max(MIN_SHEET_COLUMNS, width);
    }); // Set custom styles & formats

    snap.styles = styles; // Ensure custom sheet names are set on workbook
    // Set custom cell data for sheet and style references

    snap.sheetOrder.forEach((sheetId, index) => {
      const sheetName = getSheetName(index, sheetNames);
      snap.sheets[sheetId].name = sheetName;
      snap.sheets[sheetId].cellData = merge(snap.sheets[sheetId].cellData || {}, cellData[index] || {});
    }); // Apply any grid settings to sheet

    snap.sheetOrder.forEach((sheetId, index) => {
      const grid = grids[index];

      if (grid && grid.rowData) {
        snap.sheets[sheetId].rowData = grid.rowData;
      }

      if (grid && grid.columnData) {
        snap.sheets[sheetId].columnData = grid.columnData;
      }
    }); // Ensure that all formats in workbook meta
    // are applied their respective Univer sheet

    (formats || []).forEach((sheetFmts, index) => {
      const sheetId = snap.sheetOrder[index] || '';
      const sheet = snap.sheets[sheetId];
      if (!sheet) return; // Ensure cell data exists

      sheet.cellData = sheet.cellData || {};
      const sheetCellData = sheet.cellData;
      Object.entries(sheetFmts).forEach(_ref => {
        let [cellRef, fmt] = _ref;
        const [addr] = cellReferencesToAddress(cellRef);

        if (!sheetCellData[`${addr.row}`]) {
          sheetCellData[`${addr.row}`] = {};
        }

        if (!sheetCellData[`${addr.row}`][`${addr.col}`]) {
          sheetCellData[`${addr.row}`][`${addr.col}`] = {};
        }

        const stylesId = sheetCellData[`${addr.row}`][`${addr.col}`].s;
        const wbStyles = typeof stylesId === 'string' && styles[stylesId] || typeof stylesId === 'object' && stylesId || {}; // Format already applied via common styles, continue

        if (wbStyles.n && wbStyles.n.pattern === fmt) return; // Convert cell styles to inline style object
        // copied from workbook styles to avoid conflicts

        const cellStyles = cloneDeep(wbStyles);
        cellStyles.n = {
          pattern: fmt
        };
        sheetCellData[`${addr.row}`][`${addr.col}`].s = cellStyles;
      });
    }); // Replace any randomly generated sheet
    // identifiers with configured sheet names

    workbook.forEach((_, index) => {
      const sheetId = snap.sheetOrder[index] || '';
      const newSheetId = getSheetName(index, sheetNames);

      if (sheetId !== newSheetId) {
        snap.sheets[newSheetId] = snap.sheets[sheetId];
        snap.sheets[newSheetId].id = newSheetId;
        delete snap.sheets[sheetId];
        snap.sheetOrder[index] = newSheetId;
      }
    });
    return snap;
  } catch (err) {
    throw createWrappedError(`${PREFIX} workbookToUniverWorkbook: active workbook snapshot failed`, err);
  }
} // Convert a Workbook into a Univer Facade
// Optimized for rendering widget results:
// https://univer.ai/guides/sheet/facade/facade

export async function workbookToUniverFacade(workbook) {
  let sheetNames = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let customLogLevel = arguments.length > 2 ? arguments[2] : undefined;
  let data;
  let deps;

  try {
    data = await workbookToUniverWorkbook({
      workbook,
      workbookMeta: {
        sheetNames,
        formats: []
      },
      // Unstyled
      customLogLevel
    });
  } catch (err) {
    throw createWrappedError(`${PREFIX} workbookToUniverFacade: failed to convert workbook`, err);
  }

  try {
    deps = await load({
      includeUiDeps: true,
      excludeStyles: true
    });
  } catch (err) {
    throw createWrappedError(`${PREFIX} workbookToUniverFacade: failed to load dependencies`, err);
  }

  const {
    Univer,
    LogLevel,
    LocaleType,
    theme,
    locales,
    UniverDocsPlugin,
    UniverFormulaEnginePlugin,
    UniverRenderEnginePlugin,
    UniverSheetsPlugin,
    UniverSheetsFormulaPlugin,
    UniverSheetsUIPlugin,
    UniverDocsUIPlugin,
    UniverUIPlugin,
    FUniver
  } = deps; // @ts-ignore

  let univer;

  try {
    // Instantiate univer instance
    // @ts-ignore
    univer = new Univer({
      locales,
      theme,
      // @ts-ignore
      logLevel: customLogLevel || (IS_PRODUCTION ? LogLevel.ERROR : LogLevel.VERBOSE),
      // @ts-ignore
      locale: LocaleType.EN_US
    }); // Core plugins

    univer.registerPlugin(UniverDocsPlugin, {
      hasScroll: false
    });
    univer.registerPlugin(UniverDocsUIPlugin);
    univer.registerPlugin(UniverRenderEnginePlugin);
    univer.registerPlugin(UniverUIPlugin, {
      container: document.createElement('div'),
      header: false,
      footer: false
    });
    univer.registerPlugin(UniverSheetsPlugin);
    univer.registerPlugin(UniverSheetsUIPlugin); // Additional feature plugins

    univer.registerPlugin(UniverFormulaEnginePlugin);
    univer.registerPlugin(UniverSheetsFormulaPlugin); // Generate univer sheet

    univer.createUniverSheet(data);
  } catch (err) {
    throw createWrappedError(`${PREFIX} workbookToUniverFacade: failed to instantiate univer`, err);
  }

  try {
    // @ts-ignore
    const univerAPI = FUniver.newAPI(univer);
    await longPollUniverCompiled(univerAPI);
    return univerAPI;
  } catch (err) {
    throw createWrappedError(`${PREFIX} workbookToUniverFacade: failed to instantiate and/or compile univer`, err);
  }
} // Long-poll that 1st function cell has compiled successfully
// if one exists otherwise resolve as successfully compiled

function longPollUniverCompiled(univerAPI) {
  let attempts = 0;
  const activeWorkbook = univerAPI.getActiveWorkbook();

  if (!activeWorkbook) {
    throw Error(`${PREFIX} longPollUniverCompiled: no active workbook`);
  }

  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (attempts >= 30) {
        clearInterval(interval);
        reject(new Error('longPollUniverCompiled: univer sheet never rendered'));
      }

      attempts += 1;
      const snap = activeWorkbook.getSnapshot();
      const cellDataFunction = findFirstFunctionCellData(snap);
      const hasCompiledFunctions = cellDataFunction ? cellDataFunction.v !== undefined : true;

      if (hasCompiledFunctions) {
        clearInterval(interval);
        resolve();
      }
    }, 30);
  });
} // Find the first function cell data in a
// Univere data workbook/snapshot, if one exists


function findFirstFunctionCellData(snap) {
  let cellDataFunction = null;
  Object.entries(snap.sheets).find(_ref2 => {
    let [, sheet] = _ref2;
    Object.entries(sheet.cellData).find(_ref3 => {
      let [, row] = _ref3;
      return Object.entries(row).find(_ref4 => {
        let [, cell] = _ref4;
        const cellData = cell;

        if (cellData.f) {
          cellDataFunction = cellData;
          return true;
        }

        return false;
      });
    });
    return cellDataFunction;
  });
  return cellDataFunction;
}

// Convert a Univer Facade instance into a Workbook
export function univerFacadeToWorkbook(params) {
  const workbook = [];
  const {
    univerAPI,
    isCompiled = true
  } = params;
  const activeWorkbook = univerAPI.getActiveWorkbook();

  if (!activeWorkbook) {
    throw Error(`${PREFIX} univerFacadeToWorkbook: no active workbook`);
  }

  const snap = activeWorkbook.getSnapshot();
  const sheets = snap.sheetOrder.map(sheetId => snap.sheets[sheetId]);
  sheets.forEach(sheet => {
    const spreadsheet = [];
    Object.entries(sheet.cellData).forEach(_ref5 => {
      let [rIdx, columns] = _ref5;
      Object.entries(columns).forEach(_ref6 => {
        let [cIdx, cell] = _ref6;
        const cellData = cell;
        const rowIndex = parseInt(rIdx, 10);
        const colIndex = parseInt(cIdx, 10);
        addNonExistentRows(spreadsheet, rowIndex);
        addNonExistentColumns(spreadsheet, rowIndex, colIndex); // Lookup value/function of cell
        // if compiled only use cell value

        let value = !isCompiled && cellData.f || cellData.v;
        if (value === undefined) value = ''; // Add cell value to spreadsheet

        spreadsheet[rowIndex].splice(colIndex, 0, value);
      });
    }); // Add missing/empty matrix cells
    // so every row has same length

    addPadding(spreadsheet); // Trim empty cells on
    // right/bottom edges of matrix

    trimEdges(spreadsheet);
    workbook.push(spreadsheet);
  });
  return workbook;
} // Convert a Univer Facade instance into a Workbook Meta

export function univerFacadeToWorkbookMeta(univerAPI) {
  const meta = {
    sheetNames: [],
    formats: [],
    styles: {},
    cellData: []
  };
  const activeWorkbook = univerAPI.getActiveWorkbook();

  if (!activeWorkbook) {
    throw Error(`${PREFIX} univerFacadeToWorkbook: no active workbook`);
  }

  const snap = activeWorkbook.getSnapshot(); // Update workbook meta styles (contains univer formats)

  if (snap.styles) meta.styles = snap.styles;
  const sheets = snap.sheetOrder.map(sheetId => snap.sheets[sheetId]);
  sheets.forEach((sheet, index) => {
    const {
      name
    } = sheet;
    if (name) meta.sheetNames.splice(index, 0, name); // Collect grid data

    const rowData = toPublishableGridRowData(sheet.rowData || {});
    const columnData = toPublishableGridColumnData(sheet.columnData || {});
    const grid = deleteEmptyObjects({
      rowData,
      columnData
    });
    if (!meta.grids) meta.grids = [];
    meta.grids.push(grid || {}); // Collect style data

    const cellData = deleteEmptyObjects(toPublishableCellData(sheet.cellData || {}));
    if (!meta.formats) meta.formats = [];
    meta.formats.push(univerSheetDataToSheetFormats(sheet.cellData || {}, snap.styles));
    if (!meta.cellData) meta.cellData = [];
    meta.cellData.push(cellData || {});
  });
  return meta;
} // Convert Univer snapshot of cell data
// and styles into a map of cell formats
// publishable to workbook metadata

export function univerSheetDataToSheetFormats(data) {
  let styles = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return Object.entries(data).reduce((acc, _ref7) => {
    let [rowIdx, uniRowData] = _ref7;
    Object.entries(uniRowData).forEach(_ref8 => {
      let [colIdx, uniCellData] = _ref8;
      let fmt = '';
      const {
        s
      } = uniCellData;
      if (!s) return; // Lookup cell data specific, or worbook, style data

      const styleData = s && typeof s === 'object' && s || styles[s] || {}; // Lookup format

      if (styleData.n && styleData.n.pattern) {
        fmt = styleData.n.pattern;
      }

      if (!fmt) return;
      const row = parseInt(rowIdx, 10);
      const col = parseInt(colIdx, 10); // Add formats to map

      if (isNaN(row) || isNaN(col)) return;
      acc[coordinatesToSelection(row, col)] = fmt;
    });
    return acc;
  }, {});
} // Create list of all spreadsheet errors within a workbook

export function filterErrors(univerAPI) {
  const errors = [];
  const activeWorkbook = univerAPI.getActiveWorkbook();

  if (!activeWorkbook) {
    throw Error(`${PREFIX} filterErrors: no active workbook`);
  }

  const snap = activeWorkbook.getSnapshot();
  const sheets = snap.sheetOrder.map(sheetId => snap.sheets[sheetId]);
  sheets.forEach((sheet, sheetIdx) => {
    Object.entries(sheet.cellData).forEach(_ref9 => {
      let [rIdx, columns] = _ref9;
      Object.entries(columns).forEach(_ref10 => {
        let [cIdx, cell] = _ref10;
        const cellData = cell;

        if (spreadsheetErrorCodes.includes(`${cellData.v}`)) {
          const cellRef = coordinatesToSelection(parseInt(rIdx, 10), parseInt(cIdx, 10), sheetIdx);
          const err = createSpreadsheetError(`${cellData.v}`, cellRef, `${cellData.f || ''}`);
          errors.push(err);
        }
      });
    });
  });
  return errors;
}
// Create a hash of all customizable properties
// within a Univer Workbook data instance
export function createUniverWorkbookHash(univerAPI) {
  const activeWorkbook = univerAPI.getActiveWorkbook();

  if (!activeWorkbook) {
    throw Error(`${PREFIX} createUniverWorkbookHash: no active workbook`);
  }

  const snap = activeWorkbook.getSnapshot(); // Create Data Hash

  const data = Object.values(snap.sheets).reduce((acc, sheet, sheetIdx) => {
    acc += Object.entries(sheet.cellData).reduce((acc2, _ref11) => {
      let [rIdx, columns] = _ref11;
      acc2 += Object.entries(columns).map(_ref12 => {
        let [cIdx, cell] = _ref12;
        const cellData = cell || {}; // NOTE cellData.s can be a string or a IStyleData

        return `${sheetIdx}${rIdx}${cIdx}${cellData.f || cellData.v || ''}`;
      }).join('');
      return acc2;
    }, '');
    return acc;
  }, '');
  const sheetNames = Object.values(snap.sheets).reduce((acc, sheet) => {
    acc += sheet.name;
    return acc;
  }, ''); // Create a hash of grid settings

  const grids = Object.values(snap.sheets).reduce((acc, sheet, sheetIdx) => {
    const rowHash = Object.entries(sheet.rowData || {}).reduce((acc2, _ref13) => {
      let [rowIdx, uniRowData] = _ref13;
      const {
        h
      } = uniRowData;
      if (typeof h === 'number') acc2 += `${sheetIdx}${rowIdx}${h}`;
      return acc2;
    }, '');
    const colHash = Object.entries(sheet.columnData || {}).reduce((acc2, _ref14) => {
      let [colIdx, uniColData] = _ref14;
      const {
        w
      } = uniColData;
      if (typeof w === 'number') acc2 += `${sheetIdx}${colIdx}${w}`;
      return acc2;
    }, '');
    acc += `${rowHash}${colHash}`;
    return acc;
  }, ''); // Contains both styles and formatting

  const styles = Object.values(snap.sheets).reduce((acc, sheet, sheetIdx) => {
    Object.entries(sheet.cellData || {}).forEach(_ref15 => {
      let [rowIdx, rowData] = _ref15;
      Object.entries(rowData).forEach(_ref16 => {
        let [colIdx, cellData = {}] = _ref16;
        const {
          s
        } = cellData || {};
        const styles = typeof s === 'string' ? s : typeof s === 'object' ? JSON.stringify(s) : '';
        acc += styles ? `${sheetIdx}${rowIdx}${colIdx}${styles}` : '';
      });
    });
    return acc;
  }, JSON.stringify(snap.styles || {}));
  const sum = `${data}${sheetNames}${grids}${styles}`;
  return {
    data,
    grids,
    styles,
    sum
  };
}
export default {
  load,
  filterErrors,
  workbookToUniverWorkbook,
  workbookToUniverFacade,
  univerFacadeToWorkbook,
  univerFacadeToWorkbookMeta,
  createUniverWorkbookHash
}; // Collect only row data that's publishable

function toPublishableGridRowData(data) {
  return Object.entries(data).reduce((acc, _ref17) => {
    let [rowIdx, uniRowData] = _ref17;
    const {
      h
    } = uniRowData;
    if (typeof h === 'number') acc[rowIdx] = {
      h
    };
    return acc;
  }, {});
} // Collect only column data that's publishable


function toPublishableGridColumnData(data) {
  return Object.entries(data).reduce((acc, _ref18) => {
    let [colIdx, uniColData] = _ref18;
    const {
      w
    } = uniColData;
    if (typeof w === 'number') acc[colIdx] = {
      w
    };
    return acc;
  }, {});
} // Create a new cell data tree
// with only the permitted/stored properties


function toPublishableCellData(data) {
  return Object.entries(data).reduce((acc, _ref19) => {
    let [rowIdx, uniRowData] = _ref19;
    Object.entries(uniRowData).forEach(_ref20 => {
      let [colIdx, uniCellData] = _ref20;
      const {
        s
      } = uniCellData;

      if (s) {
        acc[rowIdx] = acc[rowIdx] || {};
        acc[rowIdx][colIdx] = {
          s
        };
      }
    });
    return acc;
  }, {});
}