import { useEffect, useState } from 'react';
import { marked } from 'marked';
import globalEvents from '@/utils/globalEvents';
import widgetSessionStorage from '@/services/session/widgets';
import templateEngine, { isUninterpolated } from '@/utils/templateEngine';
import { spreadsheet as spreadsheetCore } from '@industriousapps/excelkits-core';
import longFormCellSelectionUtils from '@/utils/reporting/longFormCellSelection';
// TODO replace this hook with abstraction(s)
export default function useParseReport(_ref) {
  let {
    reportingWidgetId,
    reportCtaThreshold,
    srcCtaPrompt,
    srcCtaButton,
    ctaPromptInputs = ''
  } = _ref;
  const {
    0: isCtaThresholdMet,
    1: setIsCtaThresholdMet
  } = useState(reportCtaThreshold ? false : true);
  const {
    0: ctaButton,
    1: setCtaButton
  } = useState('');
  const {
    0: ctaPrompt,
    1: setCtaPrompt
  } = useState('');
  const {
    0: interpolationData,
    1: setInterpolationData
  } = useState({}); // Sync any changes including report data

  useEffect(() => {
    const hasPromptData = Object.keys(interpolationData).length > 0;
    setCtaButton(marked.parseInline(isUninterpolated(srcCtaButton) ? hasPromptData ? templateEngine(srcCtaButton, interpolationData) : '' : srcCtaButton));
    setCtaPrompt(marked.parseInline(isUninterpolated(srcCtaPrompt) ? hasPromptData ? templateEngine(srcCtaPrompt, interpolationData) : '' : srcCtaPrompt));
  }, [srcCtaButton, srcCtaPrompt, interpolationData]); // Update interpolated text
  // on new report submissions

  useEffect(() => {
    // Ignore reporting without source widget
    if (!reportingWidgetId) return;
    const unsubscribe = globalEvents.subscribe('formsubmit', evt => {
      if (!evt || evt.detail !== reportingWidgetId) {
        return; // ignore unrelated form
      }

      loadReports();
    }); // Render on load

    requestAnimationFrame(loadReports);
    return unsubscribe; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadReports() {
    const [result] = widgetSessionStorage.findResults(reportingWidgetId) || [];
    if (!result) return;
    setIsCtaThresholdMet(getCtaThresholdValue(reportCtaThreshold, result)); // Generate a temporary, single row,
    // spreadsheet from specified gathered inputs

    const ctaPromptValues = ctaPromptInputs.split(',').map(longFormRef => longFormCellSelectionUtils.toResult(longFormRef, result)); // Convert row to named hash: value1, value2, etc

    setInterpolationData(longFormCellSelectionUtils.resultsToValueHash(ctaPromptValues));
  }

  return {
    ctaButton,
    ctaPrompt,
    isCtaThresholdMet
  };
} // Parse and lookup CTA threshold
// value from the reporting widget's
// results

function getCtaThresholdValue(ctaThresholdQuery, result) {
  const [srcSheetIndex, cellReference] = `${ctaThresholdQuery || ''}`.split('.'); // Reject queries missing either a
  // result identifier, sheet index, or
  // a single cell reference

  const isValidQuery = [srcSheetIndex, cellReference].every(v => typeof v !== 'undefined');
  if (!isValidQuery) return false; // Use last sheet if sheet index
  // is `-1` which is a reference to
  // the combination results
  // NOTE: using -1 sheet index is deprecated

  const sheetIndex = srcSheetIndex === '-1' ? result.data.length - 1 : parseInt(srcSheetIndex, 10); // Lookup spreadsheet

  const spreadsheet = result.data[sheetIndex];
  if (!spreadsheet) return false;
  const [target] = spreadsheetCore.cellReferencesToAddress(cellReference);
  return Boolean((spreadsheet[target.row] || [])[target.col]);
}