import design, { remCalc } from '@/config/design';
import styles from './styles.module.scss';
export default {
  styles: {
    mainCellsBorderWidth: '1px',
    mainCellMaxHeight: remCalc(522),
    mainMaxColumns: '2'
  },
  sectionDefaultStyles: {
    [`.${styles.main}`]: {
      borderTopWidth: '0',
      borderRightWidth: '0',
      borderBottomWidth: '0',
      borderLeftWidth: '0',
      borderTopStyle: 'solid',
      borderRightStyle: 'solid',
      borderBottomStyle: 'solid',
      borderLeftStyle: 'solid',
      borderTopColor: design.colors.gray20,
      borderRightColor: design.colors.gray20,
      borderBottomColor: design.colors.gray20,
      borderLeftColor: design.colors.gray20,
      paddingTop: '0',
      paddingRight: design.globals.lgPadding,
      paddingBottom: design.globals.padding,
      paddingLeft: design.globals.lgPadding
    },
    [`.${styles['main--medium']}`]: {
      borderTopWidth: '1px',
      borderRightWidth: '1px',
      borderBottomWidth: '1px',
      borderLeftWidth: '1px',
      paddingRight: '0',
      paddingLeft: '0',
      paddingBottom: '0'
    },
    [`.${styles['main--large']}`]: {
      borderTopWidth: '1px',
      borderRightWidth: '1px',
      borderBottomWidth: '1px',
      borderLeftWidth: '1px',
      paddingRight: '0',
      paddingLeft: '0',
      paddingBottom: '0'
    }
  }
};