import { FilterBase } from '@/utils/widget/createSelections/interfaces';
export default {
  styles: {
    borderTopWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '0',
    borderLeftWidth: '0'
  }
};
export const selectionConfig = {
  base: FilterBase.Series,
  seriesMinimumLength: 1
};