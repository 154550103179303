import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.6_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import deepClone from '@/utils/deepClone';
import pipe from '@/utils/pipe';
import { SelectionsDimensionsDescription, spreadsheet as spreadsheetCore } from '@industriousapps/excelkits-core';
import { deepArrayMap } from '@/utils/array';
import { FilterBase, FilterValueType } from './interfaces';
import collectPairs from './collectPairs';
import collectSeries from './collectSeries';
const {
  toCellRefs,
  getSpreadsheetIndex
} = spreadsheetCore;
const collectors = new Map();
collectors.set(FilterBase.Pairs, collectPairs);
collectors.set(FilterBase.Series, collectSeries);
const valueTypeFilters = new Map();
valueTypeFilters.set(FilterValueType.Truthy, filterTruthyValues);
valueTypeFilters.set(FilterValueType.Numbers, filterNumbericValues);
const baseValidityFilters = new Map();
baseValidityFilters.set(FilterBase.Series, [filterInvalidSeries]);
export default function findSpreadsheetSelection(config) {
  const {
    labeled,
    selection,
    collectionIndex,
    selectionFlatIndex,
    workbook,
    base,
    valueType,
    seriesMinimumLength
  } = config;
  const referenceMatrix = spreadsheetCore.cellReferencesToAddressMatrix(toCellRefs(selection));
  const configuration = {
    width: -1,
    height: -1,
    tactic: SelectionsDimensionsDescription.Wide,
    // default
    workbook,
    selection,
    collectionIndex,
    selectionFlatIndex,
    labeled,
    valueType,
    valueMatrix: [],
    referenceMatrix,
    selectionResults: [],
    seriesMinimumLength
  }; // Step #1 Setup

  const valueMatrix = getValueMatrix(configuration);
  configuration.valueMatrix = valueMatrix;
  const dimensions = getDimensions(configuration);
  configuration.width = dimensions.width;
  configuration.height = dimensions.height; // Step #2 Get tactics to try

  const preferredTactic = getPreferredTactic(configuration);
  const secondaryTactic = getSecondaryTactic(preferredTactic);
  const tactics = [preferredTactic, secondaryTactic]; // Step #3 Collect results for each tactic

  const results = tactics.map(tactic => pipe( // Collect from results
  collectors.get(base), // Filters results
  valueTypeFilters.get(valueType), ...(baseValidityFilters.get(base) || []) // Remove invalid for base
  )(_objectSpread(_objectSpread({}, deepClone(configuration)), {}, {
    tactic
  }))).sort((a, b) => // Rank by highest scoring result
  scoreSelectionResults(b.selectionResults) - scoreSelectionResults(a.selectionResults)); // Return the first, largest result

  return results[0].selectionResults;
} // Create a single value matrix from a result and
// a reference to a sheet in that result (set in selection)

function getValueMatrix(config) {
  const {
    workbook,
    referenceMatrix,
    selection
  } = config;
  const sheetIndex = getSpreadsheetIndex(selection);
  const sheet = workbook[sheetIndex] || [[]]; // Provide empty matrix for non-existent sheet

  return referenceMatrix.map(row => row.map(address => (sheet[address.row] || [])[address.col]));
} // Setup height/width of the selected spreadsheet portion


function getDimensions(config) {
  const {
    valueMatrix
  } = config;
  return {
    height: valueMatrix.length,
    width: valueMatrix[0].length
  };
} // Set filtering tactic according
// to the value matrix dimensions


function getPreferredTactic(config) {
  const {
    labeled,
    valueType,
    valueMatrix
  } = config;
  return spreadsheetCore.describeValueMatrixDimensions(valueMatrix, labeled, valueType === FilterValueType.Numbers);
} // Find a second complementary
// tactic for the primary tactic


function getSecondaryTactic(tactic) {
  if (tactic === 'flat') {
    return SelectionsDimensionsDescription.Narrow;
  } else if (tactic === 'narrow') {
    return SelectionsDimensionsDescription.Flat;
  } else if (tactic === 'wide') {
    return SelectionsDimensionsDescription.Tall;
  } else {
    return SelectionsDimensionsDescription.Wide;
  }
} // Filter out any non-truthy values
// including errors, etc.


function filterTruthyValues(config) {
  // Filter each individual selection result's data
  config.selectionResults.forEach(selectionResult => {
    selectionResult.data = selectionResult.data.filter(_ref => {
      let {
        value
      } = _ref;
      return ['string', 'boolean', 'number'].includes(typeof value) && value === value;
    } // NaN stanity check
    );
  });
  return config;
} // Filter out any non-numberic values
// including errors, etc.


function filterNumbericValues(config) {
  // Filter each individual selection result's data
  config.selectionResults.forEach(selectionResult => {
    selectionResult.data = selectionResult.data.filter(_ref2 => {
      let {
        value
      } = _ref2;
      return typeof value === 'number' && value === value;
    } // NaN stanity check
    );
  });
  return config;
} // Remove selection results lacking at
// least two values in their data and
// cannot be considered a series


function filterInvalidSeries(config) {
  const {
    seriesMinimumLength
  } = config;
  config.selectionResults = config.selectionResults.filter(selectionResult => selectionResult.data.length >= seriesMinimumLength);
  return config;
} // Create a score of the overall content contained
// with a selection (factor in values and labels)


export function scoreSelectionResults(selectionResults) {
  let score = 0;
  deepArrayMap(selectionResults, selectionResult => {
    score += selectionResult.data.reduce((acc, _ref3) => {
      let {
        value,
        label
      } = _ref3;
      acc += value === undefined ? 0 : 1;
      acc += label === undefined ? 0 : 1;
      return acc;
    }, 0);
  });
  return score;
}