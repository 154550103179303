import design from '@/config/design';
import { FilterBase, FilterValueType } from '@/utils/widget/createSelections/interfaces';
export default {
  styles: {
    borderTopWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '0',
    borderLeftWidth: '0',
    paddingTop: design.globals.lgPadding
  }
};
export const selectionConfig = {
  base: FilterBase.Series,
  valueType: FilterValueType.Numbers,
  seriesMinimumLength: 1
};