const PREFIX = 'utils: fetchJson:'; // GET a file and parse it as JSON

export default function fetchJson(url) {
  let _fetch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : fetch;

  return _fetch(url).catch(err => {
    throw Error(`${PREFIX} unexpected request failure: ${err}`);
  }).then(response => {
    if (!response.ok) {
      throw new Error(`${PREFIX} HTTP error "${response.status}" for url "${url}"`);
    }

    return parseResponse(response);
  });
}

function parseResponse(response) {
  return response.json().catch(err => {
    throw Error(`${PREFIX} parseResponse: response was not parsable as JSON: ${err}`);
  });
}