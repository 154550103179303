import { spreadsheet as coreSpreadsheet } from '@industriousapps/excelkits-core'; // const PREFIX = 'common: utils: reporting: longFormCellSelection:';

const {
  splitCellRefs,
  cellReferencesToAddress,
  getSheetName
} = coreSpreadsheet; // Convert long-form cell address `resultId.sheetIndex.cellReference`
// into it's referenced spreadsheet value by looking it up
// within provided results

export function toResult(selection, result) {
  const [sheetRef, srcCellRef] = selection.split('.'); // Ignore invalid long-form selection

  if (!sheetRef || !srcCellRef) return undefined;
  const [cellRef] = splitCellRefs(srcCellRef); // sanity check for range selections

  let sheetIndex = parseInt(sheetRef, 10); // Get combined sheet from workbook,
  // which is always the last sheet in
  // the result's data
  // NOTE: using -1 sheet index is deprecated

  if (sheetIndex === -1) sheetIndex = result.data.length - 1;
  if (isNaN(sheetIndex)) sheetIndex = 0; // Return result if any found

  if (result && result.data[sheetIndex]) {
    const [address] = cellReferencesToAddress(cellRef);
    const row = result.data[sheetIndex][address.row];
    return row ? row[address.col] : undefined;
  }

  return undefined;
} // Create user facing selection from a long form selection

export function toUserFacingSelection(selection, options) {
  const {
    addSheetRef = false,
    sheetNames = []
  } = options || {};
  let sheetName = '';

  if (addSheetRef) {
    const sheetIndex = parseInt(getSheetIndexRef(selection), 10);
    sheetName = getSheetName(sheetIndex, sheetNames);
  }

  const cellRef = selection.split('.').pop();
  return `${sheetName ? "'" + sheetName + "'" : ''}${sheetName ? '!' : ''}${cellRef}`;
} // Convert a list of results from a spreadsheet
// into a hash of named values that can be
// inserted into a template

export function resultsToValueHash(values) {
  return values.reduce((acc, value, index) => {
    acc[`value${index + 1}`] = value;
    return acc;
  }, {});
} // If no cell reference provided then
// selection is a non-result 2 segement query

export function getSheetIndexRef(selection) {
  const [resultId, sheetIndex, cellRef] = selection.split('.'); // Single cell reference default to
  // first sheet reference

  if (!sheetIndex) return '0';
  return !cellRef ? resultId : sheetIndex;
}
export default {
  toResult,
  resultsToValueHash,
  toUserFacingSelection,
  getSheetIndexRef
};