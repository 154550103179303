import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.6_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// const PREFIX = 'common: components: reporting: Form: useQuestionGrouping:';
export default function useQuestionGrouping(srcGroups, questions) {
  const groups = [...srcGroups]; // Ensure groups always has
  // at least 1 configuration

  if (groups.length < 1) {
    groups.push({
      heading: '',
      defaultOpen: true,
      isToggleable: false
    });
  }

  const groupedQuestions = questions.reduce((acc, question) => {
    const groupIndexSrc = question.group; // Default question to 1st group

    const groupIndex = typeof groupIndexSrc !== 'number' ? 0 : Math.abs(groupIndexSrc) || 0; // Sanity check if groups are out of order
    // within the questions array

    while (!Array.isArray(acc[groupIndex])) {
      acc.push([]);
    }

    acc[groupIndex].push(question);
    return acc;
  }, [[]]);
  const initialOpenGroups = groups.map((group, index) => {
    const defaultOpen = (group || {}).defaultOpen;
    const isToggleable = (group || {}).isToggleable; // Forms with single
    // groups are always open

    if (groups.length < 2) {
      return index;
    } // Untoggleable groups are always open


    if (typeof isToggleable === 'boolean' && !isToggleable) {
      return index;
    } // Check if toggleable group
    // is defaulted to open


    const isOpen = typeof defaultOpen === 'boolean' ? defaultOpen : true;
    return isOpen ? index : -1;
  }).filter(index => index >= 0); // Create new, non-initial,
  // group configuration

  const createGroup = function () {
    let config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _objectSpread({
      heading: '',
      defaultOpen: true,
      isToggleable: true
    }, config);
  };

  return {
    groups,
    groupedQuestions,
    initialOpenGroups,
    isTogglingEnabled: groupedQuestions.length > 1,
    createGroup
  };
}