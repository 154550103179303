import design from '@/config/design';
export default {
  styles: {
    borderTopWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '0',
    borderLeftWidth: '0'
  },
  inlineStyles: {
    padding: `${design.globals.lgPadding} ${design.globals.xlgPadding} ${design.globals.padding}`
  },
  sidebarStyles: {
    padding: `${design.globals.lgPadding} ${design.globals.lgPadding} 0`
  }
};