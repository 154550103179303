import { marked } from 'marked';
import { useMemo } from 'react';
import templateEngine from '@/utils/templateEngine';
import { selectionResultsToHash, seriesOptionsToHash } from '@/utils/widget/hashing';
export default function useDataOverview(_ref) {
  let {
    results,
    seriesOptions
  } = _ref;
  const resultsHash = selectionResultsToHash(results);
  const optionsHash = seriesOptionsToHash(seriesOptions);
  return useMemo(() => {
    if (!results || results.length === 0 || results[0].length === 0) {
      return {
        data: []
      };
    }

    const dataGroups = selectionGroupsToData({
      results: results[0],
      options: seriesOptions
    });
    return {
      data: dataGroups
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsHash, optionsHash]);
}
// Convert a flat-list of selection result groups
// into a list of renderable data items
export function selectionGroupsToData(_ref2) {
  let {
    results,
    options
  } = _ref2;
  return results // Pair selection group with series options
  .map(selectionResultGroup => {
    const groupOptions = options[selectionResultGroup.selectionIndex];
    return {
      group: selectionResultGroup,
      options: groupOptions
    };
  }) // Create series data
  .map(_ref3 => {
    let {
      group,
      options = {}
    } = _ref3;
    // Provide default template
    const template = options.template || `{{value}}`;
    const dataLen = (group.data || []).length;
    const [labelSrc, valueSrc] = group.data || [];
    const [,, captionSrc] = group.data || [];
    let valueResult = valueSrc;
    let labelResult = labelSrc; // For single-value non-labeled
    // selection use the label for value

    if (dataLen === 1) {
      valueResult = labelSrc;
      labelResult = undefined;
    }

    const labelValue = `${labelResult && labelResult !== valueResult && labelResult.value || ''}`;
    return {
      label: marked.parseInline(options.label || // Check singular label
      labelValue || // Check spreadsheet label value
      ''),
      value: marked.parseInline(templateEngine(template, {
        value: valueResult.value
      })),
      caption: captionSrc && captionSrc.value || '',
      highlight: options.strong || false,
      tooltip: options.tooltip || '',
      selection: group.selections,
      selectionIndex: group.selectionIndex
    };
  });
}